import React from 'react';

export const PhuQuoc = {
    "name": "Phu Quoc",
    "link": "phu-quoc",
    "country": "Vietnam",
    "continent": "Asia",
    "thumb": "https://live.staticflickr.com/65535/50380484586_202761eb74_t.jpg",
    "image": "https://live.staticflickr.com/65535/50380484586_e4edd80ea5_o.jpg",
    "caption": "The paradise island",

    "description": "Phu Quoc is Vietnam’s largest island and a well known tourist destination that’s home to sprawling resorts, tacky casinos and even an enormous amusement park. Despite now being too crowded with tourists, this paradise island surely deserves a visit, since it is still home to some of Vietnam’s best beaches, island hopping excursions, and lush jungles. If you search along the cost you will still find some hidden empty beaches that will give you time to relax and enjoy the silence of the area.",

    "nrDays": <p>
        As Phu Quoc is mostly a beach break destination, the island doesn't have so many attractions to visit. You can easily search and
        visit almost all the island in <b> 2/3 days</b>. However, if you are looking to spend a little more time relaxing in the amazing
        beaches of this spot you can extend your stay for a couple of extra days.
              </p>,

    "whenVisit": <p>
        Phu Quoc has two seasons, the wet season with a lot of rainfall and the dry season. Dry season lasts <b>from November until
        May</b>. This is the nicest time to visit Phu Quoc Island and enjoy sunny beaches all day.
        In April and May the temperatures can reach up to 40°C, so we recommend to visit the island <b>until March</b> to avoid the high temperatures and
        at the same time enjoy a magnifique weather.
        </p>,

    "mobility": <p>
        For getting around on Phu Quoc Island, you have several possibilities to take. You can go by taxi, bus, boat, bicycle or motorbike.
        <b> Renting a motorbike</b> is, in our opinion, your best option for traveling in the island’s roads.
        <br/><br/>
        When you arrive at Phu Quoc International Airport, you are immediately confronted with <b>several taxi offers</b>. You can take one here
        to your homestay but don't forget that you're still in Vietnam and need to be careful in which one you choose.
                </p>,

    "safety": <p>
        Phu Quoc is a particularly safe place to go. Scams are the most common issue that you will hear about.
        But as in any other place where you go during your travels, always pay close attention to your belongings, and don't leave them unattended on the beach.
        </p>,

    "itinerary": {
        "description": <p>
            As described before, to visit the island 2/3 days are enough. Here we present you a 3 days itinerary that will cover
            all the major points on the island.
            If you want to enjoy a little more time in Phu Quoc, choose to stay close to one of your favorite beaches in order to relax and
            recover energies in this "oasis".
        </p>,

        "days": [
            {
                "description":
                    <p>
                    On the first day head to the southern part of the island. Start your day with a visit to <b>Ho Quoc Pagoda</b>, an impressive Buddhist temple.
                    After a quick visit, head next to the longest <b>cable car</b> in the world and be amazed with the fantastic views from up above, in a journey that totals almost 30 minutes in the cable car (back and forth).
                    Enjoy your lunch near Sao Beach and take a time to relax here, close
                    to the ocean.
                    <br/><br/>
                    At the afternoon go visit the <b>Phu Quoc Prison</b>, and learn a little more about the dark times of the country.
                    If you still have some time left at the end of the day, visit the small village of <b>An Thoi</b>.
                    </p>,
                "image": "https://live.staticflickr.com/65535/50502219983_b4d1a12438_o.jpg",
                "caption": "Phu Quoc cable car."
            },
            {
                "description": <p>
                    Start your second day in the early morning with a hike in the <b>Ham Ninh Mountain Trail</b>. This trail takes about 3 hours
                    to complete and will take you to the <b>top of Mount Heaven</b>. After finishing this journey, you can go forward to the small <b>Ham Ninh village</b>,
                    one of the oldest villages of the island. Make a quick stop at the <b>Kiki Coconut Resort</b> and
                    relax in one of the hammocks.
                    <br/><br/>
                    After that enjoy the views between the Kiki Coconut and <b>Bai Thom</b>. To finish this excellent second day
                    enjoy a good dinner at the <b>Phu Quoc Night Market</b>, and taste some of the local cuisine.
                </p>,
                "image": "https://live.staticflickr.com/65535/50503091292_4c3783482d_o.jpg",
                "caption": "Kiki Coconut Resort."
            },
            {
                "description": <p>
                    Begin your last day in the island with a swim at the <b>Long Beach</b>, one the most famous beaches of Phu Quoc. After,
                    search for the <b>Dinh Cau Rock</b>, which can be found near Duong Dong Fishing Harbour. Continue your path until the <b>Ganh Dau beach</b>,
                    and go for lunch in the village. Here you can see the view to the Cambodian islands, that are only a few kilometers away.
                    <br/><br/>
                    To finish your stay make a quick visit to the <b>Suối Tranh Waterfall</b> and admire the 4 meter tall waterfall. If you have a chance,
                    hike one of the jungle trails and explore the bat cave, a large cave about 50 meters deep.
                </p>,
                "image": "https://live.staticflickr.com/65535/50502219938_dde31389d6_o.jpg",
                "caption": "Suối Tranh Waterfall."
            }
        ]
    },

    "highlights": [
        {
            "name": "Phu Quoc Cable Car",
            "description": <p>
                Enjoy the magnificent view from Phu Quoc to Pineapple Island in the longest cable car in the world. It is around 8km long and
                will offer you amazing views from all the area. The fee is around 25$ and it takes almost 15 minutes per lapse.
            </p>,
            "image": "https://live.staticflickr.com/65535/50503091207_1babcf68be_o.jpg"
        },
        {
            "name": "Phu Quoc Night Market",
            "description": <p>
           If you are a fan of Vietnamese cuisine and street food you surely should visit this market. Once the sun sets, the market gains an amazing
           vibe, with restaurants and vendors selling all kinds of ready-to-eat Vietnamese street food. You will also find here some fresh
           products to cook at your homestay if you have a kitchen for that.
            </p>,
            "image": "https://live.staticflickr.com/65535/50502937276_fd9cca98a4_o.jpg"
        },
        {
            "name": "Dinh Cau Rock Temple",
            "description": <p>
                Near the town of Duong Dong you will find an amazing area with a temple, a shrine and a lighthouse. This place, named
                Dinh Cau Rock Temple, is where the locals pray to the sea goddess before setting off on their fishing trips.
            </p>,
            "image": "https://live.staticflickr.com/65535/50503091147_61ee98c094_o.jpg"
        },
        {
            "name": "Phu Quoc Prison",
            "description": <p>
               Initially built by the French colonists, also known as <b>Coconut Tree prison</b>, this was used during the Vietnam War
               during the 1960s-70s as a place to capture, torture and murder communist prisoners of war. You will find here the history of
               some prisoner figures and also demonstrations of the hideous torture tools.
            </p>,
            "image": "https://live.staticflickr.com/65535/50503091112_04f712db6d_o.jpg"
        },
        {
            "name": "Phu Quoc Beaches",
            "description": <p>
               Phu Quoc has a high range of amazing beaches where you can relax and enjoy some fun activities. The best known are <b>Long Beach</b>,
               <b>Bai Sao Beach</b>, <b>An Thoi Beach</b>, <b>Ong Lang Beach</b> and <b>Cua Can Beach</b>. You will not be
               disappointed with a visit to each one of those places.
            </p>,
            "image": "https://live.staticflickr.com/65535/50502937196_791cb6c327_o.jpg"
        }
    ],

    "food": {
        "description": <p>

            </p>,
        "restaurants": [
            {
                "name": "Bun Cha Ha Noi - Phu Quoc",
                "description": "This restaurant is very authentic. There is only 2/3 dishes available but food is very tasty and the staff friendly.",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50503091012_52bb613355_o.jpg"
            },
            {
                "name": "The Spice House at Cassia Cottage",
                "description": "Nice staff, good atmosphere and nice outlook over the pool and beach.",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50503090997_58a1c6be43_o.jpg"
            },
            {
                "name": "Sunrise Bar",
                "description": "Great place to make some friends and enjoy the evening at the island.",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50503090942_7206cdec39_o.jpg"
            },
            {
                "name": "il Muro Italiano Restaurant",
                "description": "A friendly Sardinian chef with authentic food. Good atmosphere too.",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50503090892_1c0d7524d6_o.jpg"
            },
            {
                "name": "Tin Tin Pizza - Family Restaurant & Vietnamese Food",
                "description": "Good pizza, delicious springrolls and Vietnamese cuisine. Fast service too.",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50502219573_dfe2a35233_o.jpg"
            },
            {
                "name": "Almaz Phu Quoc",
                "description": "With 7 restaurants at this center we recommend you to taste the Japanese, Thai or Chinese one.",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50502219543_6a5f7ec26f_o.jpg"
            },
            {
                "name": "Department of Chemistry - Phu Quoc",
                "description": "With great cocktails and amazing atmosphere this is a nice place to visit in this part of the island. You will be satisfied with the Mexican food too.",
                "price": 3,
                "image": "https://live.staticflickr.com/65535/50502936971_a783b15e2f_o.jpg"
            }
        ]
    },

    "stay": {
        "description": "Phu Quoc is full of resorts from south to the north of the island. You will find a lot of offers and you only need to choose the more convenient to you. We will suggest here some different type of accommodations in different parts of the island in order for you to be able to choose the perfect spot according to your plan.",
        "budget": [
            {
                "name": "Spring Garden Homestay",
                "description": "Located on the west side of the island, near Ong Lang beach, this is a very economical option (with a shuttle to airport) for a few days of relaxing time.",
                "image": "https://live.staticflickr.com/65535/50502936961_f006f9d7db_o.jpg",
                "link": "/"
            },
            {
                "name": "Nani Homestay",
                "description": "A little more dislocated to the south than the previous one, this is the perfect choice for a backpacker.",
                "image": "https://live.staticflickr.com/65535/50502219458_934b9dcc16_o.jpg",
                "link": "/"
            },
            {
                "name": "Banana Homestay",
                "description": "On the east side of the island you will find the Banana Homestay, with amazing views to the sea and comfortable rooms.",
                "image": "https://live.staticflickr.com/65535/50502219423_11043b7a6f_o.jpg",
                "link": "/"
            }
        ],
        "mid": [
            {
                "name": "Sea Star Resort",
                "description": "A middle range resort with comfortable rooms and within 1 km from Phu Quoc Night Market and Cau Temple.",
                "image": "https://live.staticflickr.com/65535/50502219388_8d0869a240_o.jpg",
                "link": "/"
            }
        ],
        "high": [
            {
                "name": "VinOasis Phu Quoc",
                "description": "With an unlimited access to Water Park, this resort is perfect if you are looking for a few days of relaxing and fun.",
                "image": "https://live.staticflickr.com/65535/50503090717_1bce0672b4_o.jpg",
                "link": "/"
            },
            {
                "name": "Mövenpick Resort Waverly Phu Quoc",
                "description": "With a private beach this amazing resort will give you a perfect and relax stay on the island.",
                "image": "https://live.staticflickr.com/65535/50503090682_da9e2f32ed_o.jpg",
                "link": "/"
            },
            {
                "name": "JW Marriott Phu Quoc Emerald Bay Resort & Spa",
                "description": "Probably, the most luxurious spot in the island. Located on the southeast of the island this resort has everything you can hope for to have the perfect vacations.",
                "image": "https://live.staticflickr.com/65535/50502219258_ca64d6f27c_o.jpg",
                "link": "/"
            }
        ]
    }
}